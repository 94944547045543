$dark:       #254983 !default;
// $secondary:     #e57d0a !default;
$primary:          #2494F2 !default;
$body-bg: #eee;

$font-family-sans-serif:  "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
// btn-default background-image -webkit-linear-gradient(top, #adadad 0%, #535353 100%)

/*
$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
  (
    "primary":    $primary,
    "secondary":  $secondary,
    "success":    $success,
    "info":       $info,
    "warning":    $warning,
    "danger":     $danger,
    "light":      $light,
    "dark":       $dark
  ),
  $theme-colors
);
*/

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';